import React, {useEffect, useRef, useState} from 'react';
import {StepImplementationProps, StepId} from '../../types';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {DynamicStep, DynamicStepComponentProps} from '../../StepsManager/Components/DynamicStep';
import {StepHeader} from '../../StepsManager/Components/StepHeader';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {ContactAndAddressSummary} from '../../../ContactAndAddressSummary/ContactAndAddressSummary';
import {StepState} from '../../StepsManager/StepState';
import {PaymentsWidget} from '@wix/cashier-payments-widget/lazy';
import {cashierMandatoryFieldPlaceholder} from '../../../../../domain/utils/cashierMandatoryFieldPlaceholder';
import {BillingDetails} from './BillingDetails/BillingDetails';
import {NextStepButton} from '../../StepsManager/Components/NextStepButton/NextStepButton';
import {FormValues} from '@wix/form-viewer';
import {getContactDetailsFromContactFormValues, getContactFormInitialState} from '../../../Form/ContactForm';
import {StatesButtonStates} from 'wix-ui-tpa';
import {FormViewerHandle} from '@wix/form-viewer/widget';
import {classes} from './PaymentStep.st.css';

export enum PaymentStepDataHook {
  root = 'PaymentStep.root',
  header = 'PaymentStep.header',
  collapsed = 'PaymentStep.collapsed',
  open = 'PaymentStep.open',
  empty = 'PaymentStep.empty',
  continueButton = 'PaymentStep.continue',
}

const InternalPaymentStep = ({openStep, stepState}: DynamicStepComponentProps) => {
  const localeKeys = useLocaleKeys();

  const {
    checkoutStore: {checkout, setBillingContactDetails},
    paymentStore: {cashierConfiguration},
    checkoutSettingsStore: {checkoutSettings},
    formsStore: {areFormsLoaded},
  } = useControllerProps();

  const [contactFormValues, setContactFormValues] = useState<FormValues>(
    getContactFormInitialState(checkoutSettings, checkout.billingInfo?.contact)
  );

  const [buttonState, setButtonState] = useState(StatesButtonStates.IDLE);
  const [buttonDisabledState, setButtonDisabledState] = useState(!areFormsLoaded);

  useEffect(
    () => {
      if (stepState === StepState.OPEN) {
        setButtonState(StatesButtonStates.IDLE);
        setButtonDisabledState(!areFormsLoaded);
        setContactFormValues(getContactFormInitialState(checkoutSettings, checkout.billingInfo?.contact));
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [stepState]
  );

  useEffect(() => {
    setButtonDisabledState(!areFormsLoaded);
  }, [areFormsLoaded]);

  const contactFormViewer = useRef<FormViewerHandle>(null);
  const formRefs = [contactFormViewer];

  const validateAndSubmit = async () => {
    setButtonDisabledState(true);
    setButtonState(StatesButtonStates.IN_PROGRESS);
    const areFormsValidArr = await Promise.all(
      formRefs.filter((ref) => !!ref.current).map((ref) => ref.current!.validate())
    );

    // istanbul ignore else test forms
    if (!areFormsValidArr.includes(false)) {
      const contactDetails = getContactDetailsFromContactFormValues(
        contactFormValues,
        checkoutSettings,
        checkout.billingInfo?.contact
      );

      void setBillingContactDetails(contactDetails);
    } else {
      setButtonDisabledState(false);
      setButtonState(StatesButtonStates.IDLE);
    }
  };

  return (
    <>
      <StepHeader
        dataHook={PaymentStepDataHook.header}
        stepState={stepState}
        label={localeKeys.checkout.payment()}
        onEdit={openStep}
      />

      {cashierConfiguration && (
        <div className={stepState === StepState.EMPTY ? classes.paymentHidden : undefined}>
          <PaymentsWidget
            configuration={cashierConfiguration}
            locale={'en'}
            isWidgetVisible={true}
            theme={'default'}
            shouldApplySiteStyles={false}
            externalSubmitButton={true}
            paymentMethodChanged={/* istanbul ignore next */ (_paymentMethodId) => undefined}
            mandatoryFields={cashierMandatoryFieldPlaceholder}
            paymentResult={/* istanbul ignore next */ () => null}
            walletPaymentInNewTab={false}
            isSaveCCEnabled={false}
            allowManualPayment={true}
            allowRecurringPaymentOnly={false}
          />
        </div>
      )}
      {stepState === StepState.COLLAPSED && (
        <ContactAndAddressSummary contact={checkout.billingInfo?.contact} address={checkout.billingInfo?.address} />
      )}
      {stepState === StepState.OPEN && (
        <>
          <BillingDetails
            setContactFormValues={setContactFormValues}
            contactFormValues={contactFormValues}
            contactFormViewer={contactFormViewer}
          />
          <NextStepButton
            onClick={() => void validateAndSubmit()}
            text={localeKeys.checkout.continue_button_label()}
            dataHook={PaymentStepDataHook.continueButton}
            disabled={buttonDisabledState}
            buttonState={buttonState}
          />
        </>
      )}
    </>
  );
};

export const PaymentStep = ({index}: StepImplementationProps) => {
  return <DynamicStep index={index!} dataHook={PaymentStepDataHook.root} component={InternalPaymentStep} />;
};

PaymentStep.id = StepId.payment;
