import React, {useState, useEffect} from 'react';
import {StatesButton, StatesButtonStates, ThreeDotsLoader, ButtonSize} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../Widget/ControllerContext';
import {classes} from './PlaceOrderButton.st.css';

export const PlaceOrderButtonDataHook = 'place-order-button';

export interface PlaceOrderButtonProps {
  isMobile: boolean;
}

export const PlaceOrderButton = ({isMobile}: PlaceOrderButtonProps) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {isPlaceOrderButtonDisabled, clickPlaceOrderButton, placeOrderError},
    deliveryMethodStore: {beforePlaceOrderClicked},
    checkboxesStore: {areCheckoutCheckboxesValid, isSubscriptionCheckboxChecked, setWasFormSubmitted},
  } = useControllerProps();
  const [successButtonState, setSuccessButtonState] = useState(StatesButtonStates.IDLE);

  const handleSubmit = () => {
    if (!areCheckoutCheckboxesValid) {
      setWasFormSubmitted(true);
      return;
    }

    setSuccessButtonState(StatesButtonStates.IN_PROGRESS);
    beforePlaceOrderClicked();
    void clickPlaceOrderButton(isSubscriptionCheckboxChecked);
  };

  useEffect(() => {
    if (placeOrderError) {
      setSuccessButtonState(StatesButtonStates.IDLE);
    }
  }, [placeOrderError]);

  return (
    <StatesButton
      className={isMobile ? classes.placeOrderButtonMobile : classes.placeOrderButton}
      data-hook={PlaceOrderButtonDataHook}
      disabled={isPlaceOrderButtonDisabled}
      upgrade={true}
      size={ButtonSize.medium}
      onClick={handleSubmit}
      idleContent={localeKeys.checkout.place_order.place_order_button()}
      state={successButtonState}
      inProgressContent={<ThreeDotsLoader className={classes.threeDotButton} />}
    />
  );
};
